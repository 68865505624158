import {executeQuery} from '../utils';

export const queryDealList = (query, conf = {}) => {
  return executeQuery(
    `query DealList($filter: DealFilter, $source: Source, $offset: Int, $limit: Int, $order: OrderBy) {
      deals(filter: $filter, source: $source, offset: $offset, limit: $limit, order: $order) {
        items {
          id
          no
          free
          status
          abcClass
          offerType
          offerPrice {
            value {
              amount
              currency
            }
            type
          }
          propertyType
          propertyArea {
            from
            to
            unit
          }
          contact {
            name
            phone
          }
          propertyPurpose
          propertyAddresses {
            attributes {
              city_with_type
              region_with_type
            }
            street
            building {
              number
              block
              letter
            }
          }
          requirements
          hasOffers
          multiple
          hasNewOffers
          assignees {
            id
            name
            email
            phone
          }
          createdAt
          updatedAt
        }
        totalCount
      }
    }`,
    {...query},
    conf,
  );
};

export const queryDealDetails = id => {
  return executeQuery(
    `query Deal($id: ID!) {
      deal(id: $id) {
        id
        no
        status
        source
        free
        team {
          id
        }
        abcClass
        deadline
        closeComment
        closeReason
        multiple
        hasOffers
        hasNewOffers
        propertyType
        propertyAdditionalTypes
        propertyPurpose
        propertyArea {
          from
          to
          unit
        }
        propertyAddresses {
          attributes {
            city_with_type
            region_with_type
          }
          street
          building {
            number
            block
            letter
          }
        }
        offerType
        offerPrice {
          value {
            amount
            currency
          }
          type
        }
        createdAt
        updatedAt
        requirements
        comment
        assignees {
          id
          name
          email
          phone
        }
        contact {
          id
          status
          name
          type
          phone
          email
        }
      }
    }`,
    {id}
  );
};

export const queryRequestList = (query, conf = {}) => {
  return executeQuery(
    `query RequestList($filter: RequestFilter, $source: Source, $offset: Int, $limit: Int, $order: OrderBy) {
      requests(filter: $filter, source: $source, offset: $offset, limit: $limit, order: $order) {
        items {
          id
          no
          date
          type
          source
          status
          feedbackYes
          feedbackWait
          feedbackNo
          feedbackViewed
          activity
          activityDetails
          priceFrom
          priceTo
          areaFrom
          areaTo
          contact {
            name
            phone
          }
          contactName
          contactPhone
          requirements
          assignees {
            id
            name
            email
            phone
          }
          offers {
            id
            formattedAddress
          }
          createdAt
          updatedAt
          exported
          netRenter {
            id
          }
        }
        totalCount
      }
    }`,
    {...query},
    conf,
  );
};

export const queryRequestDetails = id => {
  return executeQuery(
    `query Request($id: ID!) {
      request(id: $id) {
        id
        no
        date
        type
        action
        status
        source
        result
        feedbackYes
        feedbackWait
        feedbackNo
        feedbackViewed
        waitingDeadline
        activity
        activityDetails
        priceFrom
        priceTo
        areaFrom
        areaTo
        contactName
        contactPhone
        requirements
        comment
        assignees {
          id
          name
          email
          phone
        }
        offers {
          id
          name
          status
          type
          price {
            value {
              amount
              currency
            }
            type
          }
          property {
            type {
              value
            }
            address {
              value
              attributes {
                city_with_type
                region_with_type
              }
              building {
                number
                block
                letter
              }
              street
            }
            area {
              amount
              unit
            }
            normalizedArea
          }
        }
        team {
          id
          name
        }
        createdAt
        updatedAt
        reactionTimeFormatted
        reactionMember {
          name
        }
        netRenter {
          id
          trademark
        }
        exported
      }
    }`,
    {id}
  );
};

export const queryRequestOffersIds = id => {
  return executeQuery(
    `query Request($id: ID!) {
      request(id: $id) {
        id
        offers {
          id
        }
      }
    }`,
    {id}
  );
};

export const queryRequestContact = id => {
  return executeQuery(
    `query Request($id: ID!) {
      request(id: $id) {
        id
        contact {
          id
          status
          name
          type
          phone
          email
        }
      }
    }`,
    {id}
  );
};

export const queryRequestAssignees = id => {
  return executeQuery(
    `query Request($id: ID!) {
      request(id: $id) {
        id
        assignees {
          id
          name
          email
          phone
        }
      }
    }`,
    {id}
  );
};

export const queryRequestsAnalytics = (query, conf = {}) => {
  return executeQuery(
    `query queryRequestsAnalytics($team: String, $dateFrom: String, $dateTo: String) {
      requestsAnalytics(team: $team, dateFrom: $dateFrom, dateTo: $dateTo) {
        items {
          member
          offers {
            arr
            avito
          }
          total {
            sell
            rent
          }
          wait{
            sell
            rent
          }
          no{
            sell
            rent
          }
          viewed{
            sell
            rent
          }
          avgReaction
        }
        avgReaction
      }
    }`,
    {...query},
    conf,
  );
};

export const queryOfferRequestsAnalytics = (query, conf = {}) => {
  return executeQuery(
    `query queryOfferRequestsAnalytics($offerId: String, $dateFrom: String, $dateTo: String) {
      offerRequestsAnalytics(offerId: $offerId, dateFrom: $dateFrom, dateTo: $dateTo) {
        source
        count
      }
    }`,
    {...query},
    conf,
  );
};

export const queryDealSelection = id =>{
  return executeQuery(
    `query dealOffersSelection($id: Int!) {
      dealOffersSelection(id: $id) {
        id
        secret
        emailAt,
        createdAt
        updatedAt
        createdBy{
          id
          name
        }
        offers{
          id
          name
          status
          type
          price{
            value{
              amount
              currency
            }
            type
          }
          property{
            type{
               value
            }
            area{
              amount
              unit
            }
          }
          }
          deal{
            id
            createdAt
            updatedAt
          }
          contact{
            id
            email
            formattedPersonName
          }
      }
    }`,
    {id}
  );
};

export const queryDealSelections = query => {
  return executeQuery(
    `query dealOffersSelectionList($offset: Int, $limit: Int, $filter: DealOffersSelectionFilter) {
      dealOffersSelectionList(offset: $offset, limit: $limit, filter: $filter) {
        items{
          id
          secret
          emailAt,
          createdAt
          updatedAt
          createdBy{
            id
            name
          }
          offers{
            id
            name
            status
            type
            price{
              value{
                amount
                currency
              }
              type
            }
            property{
              type{
                value
              }
              area{
                amount
                unit
              }
            }

          }
          deal{
            id
            createdAt
            updatedAt
          }
          contact{
            id
            email
            formattedPersonName
          }
        }
        totalCount
      }
    }`,
    {...query},
  );
};

export const queryDealAssignees = id => {
  return executeQuery(
    `query Deal($id: ID!) {
      deal(id: $id) {
        assignees {
          id
          name
          email
          phone
        }
      }
    }`,
    {id}
  );
};

export const queryDealContact = id => {
  return executeQuery(
    `query Deal($id: ID!) {
      deal(id: $id) {
        contact {
          id
          name
          type
          phone
          formattedPersonName
          email
        }
      }
    }`,
    {id}
  );
};

export const queryInDealOffersSelection = query => {
  return executeQuery(
    `query checkDealOffersStatus($deal: ID!, $offers: [ID!]!) {
      checkDealOffersStatus(deal: $deal, offers: $offers) {
        id
        attach
        selection
      }
    }`,
    {...query}
  );
};

export const queryNetRenterList = (query, conf = {}) => {
  return executeQuery(
    `query NetRenterList($filter: NetRenterFilter, $offset: Int, $limit: Int) {
      netRenters(filter: $filter, offset: $offset, limit: $limit) {
        items {
          id
          createdAt
          updatedAt
          type
          trademark
          areaFrom
          areaTo
          city {
            id
            name
          }
          contact {
            name
            phone
          }
        }
        totalCount
      }
    }`,
    {...query},
    conf,
  );
};

export const queryNetRenterDetails = id => {
  return executeQuery(
    `query NetRenter($id: ID!) {
      netRenter(id: $id) {
        id
        createdAt
        updatedAt
        type
        logo
        trademark
        privateRequest
        publicRequest
        areaFrom
        areaTo
        city {
          id
          name
        }
        contact {
          id
          status
          name
          type
          phone
          email
        }
        private
      }
    }`,
    {id}
  );
};

export const queryDealOffers = id => {
  return executeQuery(
    `query Deal($id: ID!) {
      deal(id: $id) {
        offers {
          id
          incoming
          status
          offer {
            id
            property {
              type {
                value
              }
              area {
                amount
                unit
              }
              address {
                attributes {
                  city_with_type
                  region_with_type
                }
                street
                building {
                  number
                  block
                  letter
                }
              }
            }
          }
        }
      }
    }`,
    {id}
  );
};

export const queryContactDeals = id => executeQuery(
  `query ContactDeals($id: ID!) {
    contactDeals(id: $id) {
      id
      status
      offerType
      propertyType
      propertyPurpose
    }
  }`,
  {id}
);

export const queryOfferDealOffers = id => executeQuery(
  `query OfferDealOffers($id: ID!) {
    offerDealOffers(id: $id) {
      incoming
      status
      deal {
        id
        propertyType
      }
      createdAt
    }
  }`,
  {id}
);

export const queryDealDocuments = id => {
  return executeQuery(
    `query Deal($id: ID!) {
      deal(id: $id) {
        documents {
          fileName
          fileSize
          mimeType
          name
          path
        }
      }
    }`,
    {id}
  );
};

export const queryAuthorizationList = (query, conf = {}) => {
  return executeQuery(
    `query authorizations($username: String!, $offset: Int, $limit: Int) {
      authorizations(username: $username, offset: $offset, limit: $limit) {
        items {
          dateTime
          ip
          userAgent
        }
        totalCount
      }
    }`,
    {...query},
    conf,
  );
};
