import {omit} from 'lodash';

const defaultState = {
  screens: {},
  offers: {},
};

const offer = (state = defaultState, {type, ...payload}) => {
  switch (type) {
    case 'SCREEN_REMOVE': {
      if (!(payload.key in state.screens)) {
        return state;
      }

      return {
        ...state,
        screens: omit(state.screens, payload.key),
      };
    }

    case 'OFFER_LIST_LOAD_STARTED':
      return {
        ...state,
        screens: {
          ...state.screens,
          [payload.key]: {
            loading: true,
            filter: payload.filter,
          },
        },
      };

    case 'OFFER_LIST_LOAD_COMPLETED': {
      return {
        ...state,
        screens: {
          ...state.screens,
          [payload.key]: {
            filter: state.screens[payload.key].filter,
            items: payload.items.map(offer => offer.id),
            page: payload.page,
            totalCount: payload.totalCount,
          },
        },
        offers: {
          ...state.offers,
          ...payload.items.reduce((offers, offer) => ({...offers, [offer.id]: offer}), {}),
        },
      };
    }

    case 'OFFER_LIST_LOAD_FAILED':
      return {
        ...state,
        screens: {
          ...state.screens,
          [payload.key]: {
            filter: state.screens[payload.key].filter,
            error: true,
          },
        },
      };

    case 'OFFER_LIST_SET_SCROLL_POSITION': {
      return {
        ...state,
        screens: {
          ...state.screens,
          [payload.key]: {
            ...state.screens[payload.key],
            scrollPosition: payload.position,
          },
        },
      };
    }

    case 'OFFER_LOAD_STARTED':
      return {
        ...state,
        screens: {
          ...state.screens,
          [payload.key]: {loading: true},
        },
      };

    case 'OFFER_LOAD_COMPLETED':
      return {
        ...state,
        screens: {
          ...state.screens,
          [payload.key]: {item: payload.offer.id},
        },
        offers: {
          ...state.offers,
          [payload.offer.id]: payload.offer,
        },
      };

    case 'OFFER_LOAD_FAILED':
      return {
        ...state,
        screens: {
          ...state.screens,
          [payload.key]: {error: true},
        },
      };

    case 'OFFER_ADD': {
      return {
        ...state,
        screens: {
          ...state.screens,
          [payload.key]: {
            items: [
              payload.offer.id,
              ...state.screens[payload.key].items,
            ],
          },
        },
        offers: {
          ...state.offers,
          [payload.offer.id]: payload.offer,
        },
      };
    }

    case 'OFFER_UPDATE': {
      return {
        ...state,
        offers: {
          ...state.offers,
          [payload.offer.id]: payload.offer,
        },
      };
    }

    case 'OFFER_SET_ORIGIN': {
      if (!state.offers[payload.id]) {
        return state;
      }

      return {
        ...state,
        offers: {
          ...state.offers,
          [payload.id]: {
            ...state.offers[payload.id],
            origin: payload.origin,
          },
        },
      };
    }

    case 'OFFER_UNSET_ORIGIN': {
      if (!state.offers[payload.id]) {
        return state;
      }

      return {
        ...state,
        offers: {
          ...state.offers,
          [payload.id]: omit(state.offers[payload.id], 'origin'),
        },
      };
    }

    case 'OFFER_ADD_ASSIGNEE': {
      if (!state.offers[payload.id]) {
        return state;
      }

      const assignees = state.offers[payload.id].assignees || [];
      if (assignees.indexOf(payload.assignee) >= 0) {
        return state;
      }

      return {
        ...state,
        offers: {
          ...state.offers,
          [payload.id]: {
            ...state.offers[payload.id],
            assignees: [
              ...assignees,
              payload.assignee,
            ],
          },
        },
      };
    }

    case 'OFFER_DELETE_ASSIGNEE': {
      const assignees = state.offers[payload.id].assignees || [];

      return {
        ...state,
        offers: {
          ...state.offers,
          [payload.id]: {
            ...state.offers[payload.id],
            assignees: assignees.filter(assignee => assignee.id !== payload.assignee),
          },
        },
      };
    }

    case 'OFFER_ADD_DOCUMENT': {
      const documents = state.offers[payload.id].documents || [];

      return {
        ...state,
        offers: {
          ...state.offers,
          [payload.id]: {
            ...state.offers[payload.id],
            documents: [
              ...documents,
              payload.document,
            ],
          },
        },
      };
    }

    case 'OFFER_REMOVE_DOCUMENT': {
      const documents = state.offers[payload.id].documents || [];

      return {
        ...state,
        offers: {
          ...state.offers,
          [payload.id]: {
            ...state.offers[payload.id],
            documents: documents.filter(({path}) => path !== payload.documentPath),
          },
        },
      };
    }

    case 'OFFER_SET_COMMENT': {
      return {
        ...state,
        offers: {
          ...state.offers,
          [payload.id]: {
            ...state.offers[payload.id],
            comment: payload.comment,
          },
        },
      };
    }

    case 'OFFER_CHANGED_PROLONGATION': {
      return {
        ...state,
        offers: {
          ...state.offers,
          [payload.id]: {
            ...state.offers[payload.id],
            avitoProlongation: payload.value,
            avitoProlongationOnce: payload.once,
          },
        },
      };
    }

    case 'OFFER_SET_STATUS': {
      return {
        ...state,
        offers: {
          ...state.offers,
          [payload.id]: {
            ...state.offers[payload.id],
            status: payload.status,
            archiveReason: null,
          },
        },
      };
    }

    case 'OFFER_ARCHIVED': {
      return {
        ...state,
        offers: {
          ...state.offers,
          [payload.id]: {
            ...state.offers[payload.id],
            status: payload.status,
            source: payload.source,
            archiveReason: payload.reason,
          },
        },
      };
    }

    default:
      return state;
  }
};

export default offer;
