import axios from 'axios';
import qs from 'qs';
import {queryDistrictsName} from '../queries/CityQuery';
import {
  queryClosedDealPressReleaseList,
  queryEventPressReleaseList,
  queryEventPressRelease,
  queryCloseDealPressRelease,
  queryPublicDealList,
  queryPublicDeal,
} from '../queries/PressRelease';
import {queryDealSelection, queryDealSelections} from '../queries';


/* PROPERTY */
export const loadPropertyList = (key, filter = null, forceReload = false) => {
  return (dispatch, getState) => {
    if (key in getState().root.property.screens && !forceReload) {
      return Promise.resolve();
    }

    dispatch({type: 'PROPERTY_LIST_LOAD_STARTED', key, filter});

    /*return fetch('/api/properties?' + qs.stringify(filter))
      .then(res => {
        if (!res.ok) {
          throw Error(res.statusText);
        }
        return res.json();
      })
      .then(data => dispatch({type: 'PROPERTY_LIST_LOAD_COMPLETED', key, ...data}))
      .catch(() => dispatch({type: 'PROPERTY_LIST_LOAD_FAILED', key}));*/

    return axios
      .get('/api/properties?' + qs.stringify(filter))
      .then(resp =>{
        dispatch({type: 'PROPERTY_LIST_LOAD_COMPLETED', key, ...resp.data});
        let districtsList = getState().root.district;
        let itemsDistrict = resp.data.items.filter(item => item.address.district && (!districtsList[item.address.district])).map(item => item.address.district);
        if(itemsDistrict.length > 0){
          let districtsNames = queryDistrictsName({
            filter: {
              id: itemsDistrict,
            },
            limit: 11111,
          });
          districtsNames.then(resp => {
            let names = resp.data.data.districts.items;
            let result = {};
            for (let index = 0; index < names.length; index++) {
              result[names[index].id] = names[index].name;
            }
            dispatch({type: 'SET_DISTRICTS', districts: result});
          });
        }
      }
      )
      .catch(error =>
        dispatch({type: 'PROPERTY_LIST_LOAD_FAILED', key, error})
      );
  };
};

export const loadProperty = (key, id, forceReload = false) => {
  return (dispatch, getState) => {
    if (key in getState().root.property.screens && !forceReload) {
      return Promise.resolve();
    }

    dispatch({type: 'PROPERTY_LOAD_STARTED', key});

    /*return fetch(`/api/properties/${id}`)
      .then(res => {
        if (!res.ok) {
          throw Error(res.statusText);
        }
        return res.json();
      })
      .then(property => {
        dispatch({type: 'PROPERTY_LOAD_COMPLETED', key, property});

        property.offers.forEach(id => {
          return fetch(`/api/offers/${id}`)
            .then(res => {
              if (!res.ok) {
                throw Error(res.statusText);
              }
              return res.json();
            })
            .then(offer => dispatch(updateOffer(offer)));
        });

        if (property.assignees) {
          property.assignees.forEach(id => {
            fetch(`/api/members/${id}`)
              .then(res => {
                if (!res.ok) {
                  throw Error(res.statusText);
                }
                return res.json();
              })
              .then(member => dispatch(updateTeamMember(member)));
          });
        }

        return Promise.resolve();
      })
      .catch(() => dispatch({type: 'PROPERTY_LOAD_FAILED', key}));*/

    return axios
      .get(`/api/properties/${id}`)
      .then(resp => {
        dispatch({type: 'PROPERTY_LOAD_COMPLETED', key, property: resp.data});

        if (resp.data.offers) {
          resp.data.offers.forEach(offer => {
            return axios
              .get(`/api/offers/${offer.id}`)
              .then(res => {
                res.data.property.owner = resp.data.owner;
                dispatch(updateOffer(res.data));
              });
          });
        }

        if (resp.data.assignees) {
          resp.data.assignees.forEach(assignee => {
            axios
              .get(`/api/members/${assignee.id}`)
              .then(resp => dispatch(updateTeamMember(resp.data)));
          });
        }
        if (resp.data.owner.length !== 0) {
          axios
            .get(`/api/contacts/${resp.data.owner.id}`)
            .then(resp => dispatch(updateContact(resp.data)));
        }

        return Promise.resolve();
      })
      .catch(error => dispatch({type: 'PROPERTY_LOAD_FAILED', key, error}));
  };
};

export const addProperty = (key, property) => {
  return {type: 'PROPERTY_ADD', key, property};
};

export const updateProperty = property => {
  return {type: 'PROPERTY_UPDATE', property};
};

export const setPropertyComment = (id, newComment, oldComment) => {
  return dispatch => {
    dispatch({type: 'PROPERTY_SET_COMMENT', id, comment: newComment});

    return axios
      .put(`/api/properties/${id}/comment`, {id, comment: newComment})
      .catch(() => {
        dispatch({type: 'PROPERTY_SET_COMMENT', id, comment: oldComment});
        throw new Error();
      });
  };
};

export const addOfferToProperty = (id, offer) => {
  return dispatch => {
    dispatch(updateOffer(offer));
    dispatch({type: 'PROPERTY_ADD_OFFER', id, offer: {id: offer.id, status: offer.status}});

    return Promise.resolve();
  };
};

export const addAssigneeToProperty = (id, assignee) => {
  return {type: 'PROPERTY_ADD_ASSIGNEE', id, assignee};
};

export const deleteAssigneeFromProperty = (id, assignee) => {
  return {type: 'PROPERTY_DELETE_ASSIGNEE', id, assignee};
};

export const addDocumentToProperty = (id, document) => {
  return {type: 'PROPERTY_ADD_DOCUMENT', id, document};
};

export const removeDocumentFromProperty = (id, documentPath) => {
  return {type: 'PROPERTY_REMOVE_DOCUMENT', id, documentPath};
};

export const setOwnerForProperty = (id, owner) => {
  return {type: 'PROPERTY_SET_OWNER', id, owner};
};

export const unsetOwnerFromProperty = id => {
  return {type: 'PROPERTY_UNSET_OWNER', id};
};

export const setPropertyListScrollPosition = (key, position) => {
  return {type: 'PROPERTY_LIST_SET_SCROLL_POSITION', key, position};
};

export const setPropertyStatus = (id, newStatus, oldStatus) => {
  return dispatch => {
    dispatch({type: 'PROPERTY_SET_STATUS', id, status: newStatus});

    return axios
      .put(`/api/properties/${id}/status`, {id, status: newStatus})
      .then(() => {})
      .catch(() =>
        dispatch({type: 'PROPERTY_SET_STATUS', id, status: oldStatus})
      );
  };
};

/* Deals */
export const setDealsListScrollPosition = (key, position) => {
  return {type: 'DEALS_LIST_SET_SCROLL_POSITION', key, position};
};
export const setDealOfferListScrollPosition = (key, position) => {
  return {type: 'DEAL_OFFER_LIST_SET_SCROLL_POSITION', key, position};
};
/* OFFER */
export const loadOfferList = (key, filter = null, forceReload = false) => {
  return (dispatch, getState) => {
    if (key in getState().root.offer.screens && !forceReload) {
      return Promise.resolve();
    }

    dispatch({type: 'OFFER_LIST_LOAD_STARTED', key, filter});

    /*return fetch('/api/offers?' + qs.stringify(filter))
      .then(res => {
        if (!res.ok) {
          throw Error(res.statusText);
        }
        return res.json();
      })
      .then(data => dispatch({type: 'OFFER_LIST_LOAD_COMPLETED', key, ...data}))
      .catch(() => dispatch({type: 'OFFER_LIST_LOAD_FAILED', key}));*/
    return axios
      .get('/api/offers?' + qs.stringify(filter))
      .then(resp => {
        dispatch({type: 'OFFER_LIST_LOAD_COMPLETED', key, ...resp.data});
        let districtsList = getState().root.district.districts;
        let itemsDistrict = resp.data.items.filter(item => item.property.address.district && (!districtsList[item.property.address.district])).map(item => item.property.address.district);
        if(itemsDistrict.length > 0){
          let districtsNames = queryDistrictsName({
            filter: {
              id: itemsDistrict,
            },
            limit: 11111,
          });
          districtsNames.then(resp => {
            let names = resp.data.data.districts.items;
            let result = {};
            for (let index = 0; index < names.length; index++) {
              result[names[index].id] = names[index].name;
            }
            dispatch({type: 'SET_DISTRICTS', districts: result});
          });
        }
      }
      )
      .catch(error => dispatch({type: 'OFFER_LIST_LOAD_FAILED', key, error}));
  };
};

export const loadOffer = (key, id, forceReload = false) => {
  return (dispatch, getState) => {
    if (key in getState().root.offer.screens && !forceReload) {
      return Promise.resolve();
    }

    dispatch({type: 'OFFER_LOAD_STARTED', key});

    /*return fetch(`/api/offers/${id}`)
      .then(res => {
        if (!res.ok) {
          throw Error(res.statusText);
        }
        return res.json();
      })
      .then(offer => {
        dispatch({type: 'OFFER_LOAD_COMPLETED', key, offer});

        if (offer.origin) {
          fetch(`/api/properties/${offer.origin}`)
            .then(res => {
              if (!res.ok) {
                throw Error(res.statusText);
              }
              return res.json();
            })
            .then(property => dispatch(updateProperty(property)));
        }

        if (offer.assignees) {
          offer.assignees.forEach(id => {
            fetch(`/api/members/${id}`)
              .then(res => {
                if (!res.ok) {
                  throw Error(res.statusText);
                }
                return res.json();
              })
              .then(member => dispatch(updateTeamMember(member)));
          });
        }

        return Promise.resolve();
      })
      .catch(() => dispatch({type: 'OFFER_LOAD_FAILED', key}));*/

    return axios
      .get(`/api/offers/${id}`)
      .then(resp => {
        resp.data.property.owner = [];

        if (resp.data.origin) {
          axios
            .get(`/api/properties/${resp.data.origin}`)
            .then(res => {
              dispatch(updateProperty(res.data));
              if(res.data.owner.length !== 0){
                resp.data.property.owner = res.data.owner;

                axios
                  .get(`/api/contacts/${res.data.owner.id}`)
                  .then(resp => dispatch(updateContact(resp.data)));
              }
            });
        }

        dispatch({type: 'OFFER_LOAD_COMPLETED', key, offer: resp.data});
        if (resp.data.assignees) {
          resp.data.assignees.forEach(assignee => {
            axios
              .get(`/api/members/${assignee.id}`)
              .then(resp => dispatch(updateTeamMember(resp.data)));
          });
        }

        if (resp.data.siblings) {
          resp.data.siblings.forEach(id => {
            return axios
              .get(`/api/offers/${id}`)
              .then(res => {
                res.data.property.owner = resp.data.property.owner;
                dispatch(updateOffer(res.data));
              });
          });
        }

        return Promise.resolve();
      })
      .catch(error => dispatch({type: 'OFFER_LOAD_FAILED', key, error}));
  };
};

export const addOffer = (key, offer) => {
  return {type: 'OFFER_ADD', key, offer};
};

export const updatePropertyOffer = (offerId, propertyId, status) => {
  return {type: 'PROPERTY_OFFER_UPDATE', offerId, propertyId, status};
};

export const updateOffer = offer => {
  return {type: 'OFFER_UPDATE', offer};
};

export const unsetOffer = offer => {
  return {type: 'UNSET_OFFER', offer};
};

export const setOfferListScrollPosition = (key, position) => {
  return {type: 'OFFER_LIST_SET_SCROLL_POSITION', key, position};
};

export const setOfferOrigin = (id, origin) => {
  return {type: 'OFFER_SET_ORIGIN', id, origin};
};

export const unsetOfferOrigin = id => {
  return {type: 'OFFER_UNSET_ORIGIN', id};
};

export const addAssigneeToOffer = (id, assignee) => {
  return {type: 'OFFER_ADD_ASSIGNEE', id, assignee};
};

export const deleteAssigneeFromOffer = (id, assignee) => {
  return {type: 'OFFER_DELETE_ASSIGNEE', id, assignee};
};

export const addDocumentToOffer = (id, document) => {
  return {type: 'OFFER_ADD_DOCUMENT', id, document};
};

export const removeDocumentFromOffer = (id, documentPath) => {
  return {type: 'OFFER_REMOVE_DOCUMENT', id, documentPath};
};

export const setOfferComment = (id, newComment, oldComment, activityEventsList) => {
  return dispatch => {
    dispatch({type: 'OFFER_SET_COMMENT', id, comment: newComment});

    return axios
      .put(`/api/offers/${id}/comment`, {id, comment: newComment})
      .then(() => activityEventsList && activityEventsList.updateActivity())
      .catch(() => {
        dispatch({type: 'OFFER_SET_COMMENT', id, comment: oldComment});
        throw new Error();
      });
  };
};

export const changeAvitoProlongation = (id, value, once) => {
  return dispatch => {
    dispatch({type: 'OFFER_CHANGED_PROLONGATION', id, value: value, once: once});

    return axios
      .post('/api/v1/changeOfferAvitoProlongation', {id, value: value, once: once})
      .catch(() => {
        dispatch({type: 'OFFER_CHANGED_PROLONGATION', id, value: value, once: once});
        throw new Error();
      });
  };
};

export const setOfferStatus = (id, newStatus, oldStatus, propertyId, oldReason, activityEventsList) => {
  return dispatch => {
    dispatch({type: 'OFFER_SET_STATUS', id, status: newStatus});

    return axios
      .put(`/api/offers/${id}/status`, {id, status: newStatus})
      .then(() => {
        dispatch( {type: 'PROPERTY_OFFER_UPDATE', offerId: id, propertyId: propertyId , status: newStatus});
        activityEventsList && activityEventsList.updateActivity();
      })
      .catch(() => dispatch({type: 'OFFER_SET_STATUS', id, status: oldStatus, archiveReason: oldReason}));
  };
};

export const archivedOffer = (id, oldStatus, propertyId, source, reason) => {
  const newStatus = 'archived';
  return dispatch => {
    dispatch({type: 'OFFER_ARCHIVED', id, status: newStatus, source, reason});
    dispatch({type: 'PROPERTY_OFFER_UPDATE', offerId: id, propertyId: propertyId , status: newStatus});
  };
};

/* CONTACT */
export const loadContactList = (key, filter = null, forceReload = false) => {
  return (dispatch, getState) => {
    if (key in getState().root.contact.screens && !forceReload) {
      return Promise.resolve();
    }

    dispatch({type: 'CONTACT_LIST_LOAD_STARTED', key, filter});

    return axios
      .get('/api/contacts?' + qs.stringify(filter))
      .then(resp =>
        dispatch({type: 'CONTACT_LIST_LOAD_COMPLETED', key, ...resp.data})
      )
      .catch(error => dispatch({type: 'CONTACT_LIST_LOAD_FAILED', key, error}));
  };
};

export const loadContact = (key, id, forceReload = false) => {
  return (dispatch, getState) => {
    if (key in getState().root.contact.screens && !forceReload) {
      return Promise.resolve();
    }

    dispatch({type: 'CONTACT_LOAD_STARTED', key});

    return axios
      .get(`/api/contacts/${id}`)
      .then(resp => {
        dispatch({type: 'CONTACT_LOAD_COMPLETED', key, contact: resp.data});

        if (resp.data.assignees) {
          resp.data.assignees.forEach(assignee => {
            axios
              .get(`/api/members/${assignee.id}`)
              .then(resp => dispatch(updateTeamMember(resp.data)));
          });
        }

        if (resp.data.relationships) {
          resp.data.relationships.forEach(({id}) => {
            axios
              .get(`/api/contacts/${id}`)
              .then(resp => dispatch(updateContact(resp.data)));
          });
        }

        if (resp.data.properties) {
          resp.data.properties.forEach(id => {
            axios
              .get(`/api/properties/${id}`)
              .then(resp => dispatch(updateProperty(resp.data)));
          });
        }

        return Promise.resolve();
      })
      .catch(error => dispatch({type: 'CONTACT_LOAD_FAILED', key, error}));
  };
};

export const addContact = (key, contact) => {
  return {type: 'CONTACT_ADD', key, contact};
};

export const updateContact = contact => {
  return {type: 'CONTACT_UPDATE', contact};
};

export const setContactListScrollPosition = (key, position) => {
  return {type: 'CONTACT_LIST_SET_SCROLL_POSITION', key, position};
};

export const addAssigneeToContact = (id, assignee) => {
  return {type: 'CONTACT_ADD_ASSIGNEE', id, assignee};
};

export const deleteAssigneeFromContact = (id, assignee) => {
  return {type: 'CONTACT_DELETE_ASSIGNEE', id, assignee};
};

export const addRelationshipToContact = (id, relationship) => {
  return {type: 'CONTACT_ADD_RELATIONSHIP', id, relationship};
};

export const deleteRelationshipFromContact = (id, relationship) => {
  return {type: 'CONTACT_DELETE_RELATIONSHIP', id, relationship};
};

export const addPropertyToContact = (id, property) => {
  return {type: 'CONTACT_ADD_PROPERTY', id, property};
};

export const addDocumentToContact = (id, document) => {
  return {type: 'CONTACT_ADD_DOCUMENT', id, document};
};

export const removeDocumentFromContact = (id, documentPath) => {
  return {type: 'CONTACT_REMOVE_DOCUMENT', id, documentPath};
};

export const setContactComment = (id, newComment, oldComment) => {
  return dispatch => {
    dispatch({type: 'CONTACT_SET_COMMENT', id, comment: newComment});

    return axios
      .put(`/api/contacts/${id}/comment`, {id, comment: newComment})
      .catch(() => {
        dispatch({type: 'CONTACT_SET_COMMENT', id, comment: oldComment});
        throw new Error();
      });
  };
};

export const setContactPublicInfo = (id, newInfo, oldInfo) => {
  return dispatch => {
    dispatch({type: 'CONTACT_SET_INFO', id, publicInfo: newInfo});

    return axios
      .put(`/api/contacts/${id}/publicInfo`, {id, publicInfo: newInfo})
      .catch(() => {
        dispatch({type: 'CONTACT_SET_INFO', id, publicInfo: oldInfo});
        throw new Error();
      });
  };
};

export const setContactStatus = (id, newStatus, oldStatus) => {
  return dispatch => {
    dispatch({type: 'CONTACT_SET_STATUS', id, status: newStatus});

    return axios
      .put(`/api/contacts/${id}/status`, {id, status: newStatus})
      .then(() => {})
      .catch(() =>
        dispatch({type: 'CONTACT_SET_STATUS', id, status: oldStatus})
      );
  };
};

/* TEAM */
export const loadSelfUser = () => {
  return dispatch => {
    return axios
      .get('/api/users/self')
      .then(resp =>
        dispatch({type: 'SELF_USER_LOAD_COMPLETED', selfUser: resp.data})
      )
      .catch(error => dispatch({type: 'SELF_USER_LOAD_FAILED', error}));
  };
};

export const loadSelfUserTeams = () => {
  /*return dispatch => {
    return fetch(`/api/users/self/teams`)
      .then(res => {
        if (!res.ok) {
          throw Error(res.statusText);
        }
        return res.json();
      })
      .then(teams => dispatch({type: 'SELF_USER_TEAMS_LOAD_COMPLETED', teams}))
      .catch(() => dispatch({type: 'SELF_USER_TEAMS_LOAD_FAILED'}));
  };*/

  return dispatch => {
    return axios
      .get('/api/users/self/teams')
      .then(resp =>
        dispatch({type: 'SELF_USER_TEAMS_LOAD_COMPLETED', teams: resp.data})
      )
      .catch(error => dispatch({type: 'SELF_USER_TEAMS_LOAD_FAILED', error}));
  };
};

export const loadTeamMemberList = (
  key,
  teamId,
  filter = null,
  forceReload = false
) => {
  return (dispatch, getState) => {
    if (key in getState().root.team.screens && !forceReload) {
      return Promise.resolve();
    }

    dispatch({type: 'TEAM_MEMBER_LIST_LOAD_STARTED', key, filter});

    /*return fetch(teamId ? `/api/teams/${teamId}/members` : '/api/members')
      .then(res => {
        if (!res.ok) {
          throw Error(res.statusText);
        }
        return res.json();
      })
      .then(members => dispatch({type: 'TEAM_MEMBER_LIST_LOAD_COMPLETED', key, members}))
      .catch(() => dispatch({type: 'TEAM_MEMBER_LIST_LOAD_FAILED', key}));*/

    return axios
      .get(teamId ? `/api/teams/${teamId}/members?` + qs.stringify(filter) : '/api/members')
      .then(resp =>
        dispatch({type: 'TEAM_MEMBER_LIST_LOAD_COMPLETED', key, ...resp.data})
      )
      .catch(error =>
        dispatch({type: 'TEAM_MEMBER_LIST_LOAD_FAILED', key, error})
      );
  };
};

export const addTeamMember = (key, member) => {
  return {type: 'TEAM_MEMBER_ADD', key, member};
};

export const updateTeamMember = member => {
  return {type: 'TEAM_MEMBER_UPDATE', member};
};

/* SCREEN */

export const removeScreen = key => {
  return {type: 'SCREEN_REMOVE', key};
};

/* LIGHTBOX */

export const openLightbox = (images, currentIndex) => {
  return {
    type: 'OPEN_LIGHTBOX',
    images,
    currentIndex,
  };
};

export const closeLightbox = () => {
  return {
    type: 'CLOSE_LIGHTBOX',
  };
};

export const nextImageInLightbox = () => {
  return {
    type: 'NEXT_IMAGE_IN_LIGHTBOX',
  };
};

export const prevImageInLightbox = () => {
  return {
    type: 'PREV_IMAGE_IN_LIGHTBOX',
  };
};

/* CLASSIFICATION */

export const loadDealSourcesClassification = () => {
  return _dispatch => {
    /*return fetch(`/api/deal_sources`)
      .then(res => res.json())
      .then(dealSources => {
        dispatch({
          type: 'LOAD_DEAL_SOURCES_CLASSIFICATION',
          dealSources,
        });
      });*/
  };
};

export const loadPropertyTypesClassification = () => {
  return dispatch => {
    /*return fetch(`/api/property_types`)
      .then(res => res.json())
      .then(propertyTypes => {
        dispatch({
          type: 'LOAD_PROPERTY_TYPES_CLASSIFICATION',
          propertyTypes,
        });
      });*/

    return axios.get('/api/property_types').then(resp => {
      dispatch({
        type: 'LOAD_PROPERTY_TYPES_CLASSIFICATION',
        propertyTypes: resp.data,
      });
    });
  };
};

export const loadPropertyPurposedClassification = () => {
  return dispatch => {
    /*return fetch(`/api/property_purposes`)
      .then(res => res.json())
      .then(propertyPurposes => {
        dispatch({
          type: 'LOAD_PROPERTY_PURPOSES_CLASSIFICATION',
          propertyPurposes,
        });
      });*/

    return axios.get('/api/property_purposes').then(resp => {
      dispatch({
        type: 'LOAD_PROPERTY_PURPOSES_CLASSIFICATION',
        propertyPurposes: resp.data,
      });
    });
  };
};

export const loadReferences = () => {
  return dispatch => {
    return axios.get('/api/v1/getReferences').then(resp => {
      dispatch({
        type: 'LOAD_REFERENCES',
        data: resp.data,
      });
    });
  };
};

/* APPLICATION */

export const setTitle = (title, color) => {
  return {type: 'SET_TITLE', title, color};
};

export const setFavicon = favicon => {
  return {type: 'SET_FAVICON', favicon};
};

export const setVersion = version => {
  localStorage.setItem('version', version);
  localStorage.setItem('timeVersion', (new Date()).getTime().toString());
  return {type: 'SET_VERSION', version};
};

/* AUTHENTICATION */

export const authenticate = () => {
  localStorage.setItem('authenticated', new Date().toISOString());
  return {type: 'AUTHENTICATE'};
};

export const logout = () => {
  localStorage.removeItem('authenticated');
  localStorage.removeItem('unreadNotification');
  localStorage.removeItem('totalCountNotification');
  localStorage.removeItem('timeNotification');
  localStorage.removeItem('newCountNotification');
  return {type: 'LOGOUT'};
};

/* USER SETTINGS */

export const getUserSettings = () => {
  return dispatch => {
    let listSettings = {};
    if (localStorage.getItem('ListSettings_v2')) {
      listSettings = JSON.parse(localStorage.getItem('ListSettings_v2'));
    }
    dispatch({type: 'GET_USER_SETTINGS', listSettings});
  };
};

export const setListSettings = (entityList, data) => {
  return dispatch => {
    let newSettings = {};
    let columns = data.columns;
    let page = data.page;
    let listSettings = localStorage.getItem('ListSettings_v2');
    if (listSettings) {
      listSettings = JSON.parse(listSettings);
      newSettings = {
        ...listSettings,
        [entityList]: {
          columns,
          page,
        },
      };
    } else {
      newSettings = {
        [entityList]: {
          columns,
          page,
        },
      };
    }
    newSettings = JSON.stringify(newSettings);

    localStorage.setItem('ListSettings_v2', newSettings);
    dispatch({type: 'SET_LIST_SETTINGS', entityList, columns, page});
  };
};


export const setCity = city => {
  return dispatch => {
    dispatch({
      type: 'SET_CITY',
      city,
    });
  };
};

export const setDistrict = district => {
  return dispatch => {
    dispatch({
      type: 'SET_DISTRICT',
      district,
    });
  };
};

/* Hint */
export const setCityHint = city => {
  return dispatch => {
    dispatch({
      type:'SET_CITY_HINT',
      ...city,
    });
  };
};

export const setDistrictHint = district => {
  return dispatch => {
    dispatch({
      type:'SET_DISTRICT_HINT',
      ...district,
    });
  };
};

export const setSubDistrictHint = subDistrict => {
  return dispatch => {
    dispatch({
      type:'SET_SUB_DISTRICT_HINT',
      ...subDistrict,
    });
  };
};

/* Names */
export const updatePropertyNames = array => {
  return dispatch => {
    dispatch({
      type: 'SET_PROPERTY_NAME',
      ...array,
    });
  };
};

export const updateOfferNames = array => {
  return dispatch => {
    dispatch({
      type: 'SET_OFFER_NAME',
      ...array,
    });
  };
};

export const updateContactNames = array => {
  return dispatch => {
    dispatch({
      type: 'SET_CONTACT_NAME',
      ...array,
    });
  };
};

export const updateMemberNames = array => {
  return dispatch => {
    dispatch({
      type: 'SET_MEMBER_NAME',
      ...array,
    });
  };
};

export const updateTeamNames = array => {
  return dispatch => {
    dispatch({
      type: 'SET_TEAM_NAME',
      ...array,
    });
  };
};


/* Press-Release */
export const loadCloseDealPressRelease = filter => {
  return dispatch => {
    dispatch({
      type: 'LOAD_PRESS_RELEASE_STARTED',
    });
    queryClosedDealPressReleaseList(filter).then(resp => {
      let items =  resp.data.data.closedDealPressReleaseList.items.reduce((items, item) => ({...items, [item.id]: item}), {});
      let totalCount = resp.data.data.closedDealPressReleaseList.total;
      dispatch({
        type: 'LOAD_PRESS_RELEASE_CLOSE_DEAL_LIST',
        pressReleaseList: {items, totalCount},
      });
    });
  };
};

export const loadEventPressRelease = filter => {
  return dispatch => {
    dispatch({
      type: 'LOAD_PRESS_RELEASE_STARTED',
    });
    queryEventPressReleaseList(filter).then(resp => {
      let items =  resp.data.data.eventPressReleaseList.items.reduce((items, item) => ({...items, [item.id]: item}), {});
      let totalCount =  resp.data.data.eventPressReleaseList.total;
      dispatch({
        type: 'LOAD_PRESS_RELEASE_EVENT_LIST',
        pressReleaseList: {items, totalCount},
      });
    });
  };
};

export const loadPublicDeals = filter => {
  return dispatch => {
    dispatch({
      type: 'LOAD_PUBLIC_DEAL_LIST_STARTED',
    });
    queryPublicDealList(filter).then(resp => {
      let items = resp.data.data.publicDealList.items.reduce((items, item) => ({...items, [item.id]: item}), {});
      let totalCount = resp.data.data.publicDealList.total;
      dispatch({
        type: 'LOAD_PUBLIC_DEAL_LIST',
        publicDealList: {items, totalCount},
      });
    });
  };
};

export const loadEventPressReleaseDetails = id => {
  return dispatch => {
    dispatch({
      type: 'LOAD_PRESS_RELEASE_STARTED',
    });
    queryEventPressRelease(id).then(resp => {
      let item = resp.data.data.eventPressRelease;
      dispatch({
        type: 'LOAD_PRESS_RELEASE_EVENT_DETAILS',
        item,
      });

    });
  };
};

export const loadCloseDealPressReleaseDetails = id => {
  return dispatch => {
    dispatch({
      type: 'LOAD_PRESS_RELEASE_STARTED',
    });

    queryCloseDealPressRelease(id).then(resp => {
      let item = resp.data.data.closedDealPressRelease;
      dispatch({
        type: 'LOAD_PRESS_RELEASE_CLOSE_DEAL_DETAILS',
        item,
      });
    });
  };
};

export const loadPublicDeal = id => {
  return dispatch => {
    dispatch({
      type: 'LOAD_PUBLIC_DEAL_STARTED',
    });
    queryPublicDeal(id).then(resp => {
      let item = resp.data.data.publicDeal;
      if (!item) throw new Error();
      item.photos = item.photo ? [item.photo] : [];
      dispatch({
        type: 'LOAD_PUBLIC_DEAL',
        item,
      });
    });
  };
};

export const updatePressReleaseCloseDeal = pressRelease => {
  return dispatch => {
    dispatch({
      type: 'UPDATE_PRESS_RELEASE_CLOSE_DEAL',
      pressRelease,
    });
  };
};

export const updatePressReleaseEvent = pressRelease => {
  return dispatch => {
    dispatch({
      type: 'UPDATE_PRESS_RELEASE_EVENT',
      pressRelease,
    });
  };
};

export const updatePublicDeal = publicDeal => {
  return dispatch => {
    dispatch({
      type: 'UPDATE_PUBLIC_DEAL',
      publicDeal,
    });
  };
};

export const removePublicDeal = (id, callback) => {
  return dispatch => {
    return axios
      .post('/api/v1/pressRelease/removePublicDeal', {id})
      .then(() => {
        dispatch({type: 'PUBLIC_DEAL_REMOVED', id});
        callback();
      })
      .catch(() => {
        throw new Error();
      });
  };
};

export const setPressReleaseCloseDealComment = (id, newComment) => {
  return dispatch => {
    return axios
      .post('/api/v1/pressRelease/setClosedDealComment', {id, comment: newComment})
      .then(() => {
        dispatch({type: 'PRESS_RELEASE_CLOSE_DEAL_SET_COMMENT', id, comment: newComment});
      })
      .catch(() => {

        throw new Error();
      });
  };
};
export const setPressReleaseEventComment = (id, newComment) => {
  return dispatch => {
    return axios
      .post('/api/v1/pressRelease/setEventComment', {id, comment: newComment})
      .then(() => {
        dispatch({type: 'PRESS_RELEASE_EVENT_SET_COMMENT', id, comment: newComment});
      })
      .catch(() => {
        throw new Error();
      });
  };
};

export const setPressReleaseCloseDealPublished = (id, published) => {
  return {type: 'PRESS_RELEASE_CLOSE_DEAL_SET_PUBLISHED', id, published};
};

export const setPressReleaseEventPublished = (id, published) => {
  return {type: 'PRESS_RELEASE_EVENT_SET_PUBLISHED', id, published};
};

export const setPressReleaseEventArchived = (id, archived) => {
  return {type: 'PRESS_RELEASE_EVENT_SET_ARCHIVED', id, archived};
};

export const setPressReleaseCloseDealArchived = (id, archived) => {
  return {type: 'PRESS_RELEASE_CLOSE_DEAL_SET_ARCHIVED', id, archived};
};

export const addAssigneeToPressRelease = (id, assignee) => {
  return {type: 'PRESS_RELEASE_ADD_ASSIGNEE', id, assignee};
};

export const deleteAssigneeFromPressRelease = (id, assignee) => {
  return {type: 'PRESS_RELEASE_DELETE_ASSIGNEE', id, assignee};
};

/* Press-Release */
export const loadDealSelections = query => {
  return dispatch => {
    queryDealSelections(query).then(resp => {
      if(!resp.data.error)
        dispatch({type: 'LOAD_SELECTIONS', items: resp.data.data.dealOffersSelectionList.items, totalCount: resp.data.data.dealOffersSelectionList.totalCount});
    });
  };
};

export const loadDealSelection = dealId => {
  return dispatch => {
    queryDealSelection(dealId).then(resp => {
      if(!resp.data.error && resp.data.data.dealOffersSelection)
        dispatch({type: 'LOAD_SELECTION', selection: resp.data.data.dealOffersSelection});
      else
        dispatch({type: 'LOAD_SELECTION_FAILED'});
    });
  };
};

export const startChangeSelection = selection => {
  return dispatch => {
    dispatch({type: 'START_CHANGE_SELECTION', selection});
  };
};


export const deleteOfferFromDealSelection =  offerId => {
  return dispatch => {
    dispatch({type: 'DELETE_OFFER_FROM_SELECTION', offerId});
  };
};

export const cancelChangeSelection = () => {
  return dispatch => {
    dispatch({type: 'CANCEL_CHANGE_SELECTION'});
  };
};

export const changeDealSelection = () => {
  return dispatch => {
    dispatch({type: 'CHANGE_DEAL_SELECTION'});
  };
};

export const changeOffersFromSelection = offers => {
  return dispatch => {
    dispatch({type: 'CHANGE_OFFERS_FROM_SELECTION', offers});
  };
};

export const finishChangeSelection = () => {
  return dispatch => {
    dispatch({type: 'FINISH_CHANGE_SELECTION'});
  };
};

export const changePageSelection = page => {
  return dispatch => {
    dispatch({type: 'CHANGE_PAGE_SELECTIONS', page});
  };
};
