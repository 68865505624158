import gql from 'graphql-tag';

export const queryStatistic = gql`
  query dealsStatistics($filter: DealsStatisticsFilter) {
    dealsStatistics(filter: $filter){
      statuses {
        status
        count
        commission
      }
      sources {
        source
        count
        commission
      }
      classes {
        class
        count
        commission
      }
      offerTypes {
        type
        count
        commission
      }
      propertyTypes {
        type
        count
        commission
      }
      total {
        count
        commission
      }
    }
  }`;

export const queryDynamicStatics = gql`
  query dealsStatistics($filter: DealsStatisticsFilter) {
    dealsStatistics(filter: $filter){
      dynamics {
        date
        count
      }
      total {
        count
        commission
      }
    }
  }`;

export const queryRequestStatistic = gql`
  query requestsStatistics($filter: DealsStatisticsFilter) {
    requestsStatistics(filter: $filter){
      sources {
        source
        count
        commission
      }
      offerTypes {
        type
        count
        commission
      }
      activities {
        activity
        count
        commission
      }
      total {
        count
        commission
      }
    }
  }`;

export const queryRequestDynamicStatics = gql`
  query requestsStatistics($filter: DealsStatisticsFilter) {
    requestsStatistics(filter: $filter){
      dynamics {
        date
        count
      }
      total {
        count
        commission
      }
    }
  }`;

export const queryDealOfferStatistic = gql`
    query dealOfferStatistics($filter: DealOfferStatisticsFilter) {
      dealOfferStatistics(filter: $filter){
        items {
          id
          address
          counts {
            source
            count
          }
          avitoDays
          total
          ignoreExports
          exportAvitoTimer
        }
        total
      }
    }`;

export const offersWithoutDealList = gql`
  query offersWithoutDealList($filter: OfferWithoutDealFilter) {
    offersWithoutDealList(filter: $filter){
      id
      address
      createdAt
      updatedAt
      ignoreExports
      exportAvitoTimer
      avitoDays
    }
  }`;

export const queryRequestOfferStatistic = gql`
  query requestOfferStatistics($filter: DealOfferStatisticsFilter) {
    requestOfferStatistics(filter: $filter){
      items {
        id
        address
        counts {
          source
          count
        }
        avitoDays
        total
        ignoreExports
        exportAvitoTimer
      }
      total
    }
  }`;

export const queryRequestWithoutOffersStatistics = gql`
  query requestWithoutOffersStatistics($filter: OfferWithoutDealFilter) {
    requestWithoutOffersStatistics(filter: $filter){
      id
      address
      createdAt
      updatedAt
      ignoreExports
      exportAvitoTimer
      avitoDays
    }
  }`;

export const queryArchivedOffersStatistics = gql`
  query archivedOffersStatistics($filter: OfferStatisticsFilter) {
    archivedOffersStatistics(filter: $filter){
      items {
        id
        member
        reason_owner {
          broker
          disposal
          site
          social
          company
        }
        reason_we {
          broker
          disposal
          site
          social
          company
        }
        reason_refuse {
          broker
          disposal
          site
          social
          company
        }
      }
      total
    }
  }`;
